<template>
  <div class="home-container">
    <div class="box has-background-white-ter no-border-print shared-error" v-if="shareNotFound">
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"
                     v-show="doodleForm.errorMessage"/>
    </div>
    <div class="code-editor-box box main-box no-border-print" v-if="!shareNotFound">
      <div v-bind:class="{'columns is-marginless': isBlockly}">
        <div id="blocklyEditor" style="height: 400px" class="column is-6 blockly-editor no-border-print" v-if="isBlockly"></div>
        <div v-bind:class="{'column is-6 blockly-editor': isBlockly}">
          <div id="code" class="code-editor no-border-print" ></div>
        </div>
      </div>
      <div v-if="this.$route.meta.isPlugin">
        <section class="accordions">
          <article class="accordion" v-bind:class="{'is-active' : showAccordion}">
            <div class="accordion-header toggle background-grey-light has-text-weight-semibold" @click="revertAccordion">
              <p><font-awesome-icon icon="chevron-down"/>&nbsp;&nbsp;&nbsp;Execute Mode, Version, Inputs & Arguments</p>
            </div>
            <div class="accordion-body custom-grey">
              <div class="accordion-content is-paddingless">
                <div class="columns is-multiline options-section is-marginless padding-left-none">
                  <div class="column columns is-multiline left-column is-marginless padding-left-none is-print-12" v-bind:class="{'is-6':!interactiveMode, 'is-12':interactiveMode}">
                    <div class="column is-hidden-print" v-bind:class="{'is-12': !interactiveMode, 'is-6':interactiveMode, 'interactive-mode-top':interactiveMode}">
                      <div class="version-interactive-section is-mobile level">
                        <div class="has-text-centered level-left">
                          <div class="field is-narrow">
                            <div class="select is-rounded is-small has-text-weight-bold version-dropdown">
                              <select v-model="versionIndex" @change="versionChanged">
                                <option v-for="(version, index) in versions" v-bind:value="index" v-bind:key="version">
                                  {{version}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="field has-text-centered level-right">
                          <input id="interactiveMode1"  v-model="interactiveModeOption"  type="checkbox" name="interactiveMode" class="switch has-text-white"/>
                          <label for="interactiveMode1" class="ide-title has-text-weight-semibold">Interactive</label>
                        </div>
                      </div>
                    </div>
                    <div class="column padding-left-none is-print-12" v-bind:class="{'is-12':!interactiveMode, 'is-6':interactiveMode, 'padding-top-none':interactiveMode}">
                      <div v-show="showCommandLine" class="column is-12 is-print-12" v-bind:class="{'padding-top-none':interactiveMode}">
                        <div class="has-text-weight-semibold ide-title"  v-bind:class="{'is-marginless':interactiveMode}">CommandLine Arguments</div>
                        <div class="control">
                          <input v-model="args" class="input" type="text" name="arguments" autocomplete="off"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6 is-print-12" v-show="!interactiveMode">
                    <div class="has-text-weight-semibold ide-title">Stdin Inputs</div>
                    <div class="control">
                      <textarea rows=2 class="textarea" v-model="stdin" name="stdin"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div v-else>
        <div>
          <div v-show="showArgs">
            <div class="has-text-weight-semibold ide-title">CommandLine Arguments</div>
            <div class="control">
              <input v-model="args" class="input" type="text" name="arguments" autocomplete="off"/>
            </div>
          </div>
        </div>
        <div class="padding-left-none  is-hidden-print" v-show="showStdIn">
          <div class="field interactive-mode-top">
            <input id="interactiveMode" v-model="interactiveModeOption" type="checkbox" name="interactiveMode"
                   class="switch is-info"/>
            <label for="interactiveMode" class="ide-title has-text-weight-semibold">Interactive Mode</label>
          </div>
        </div>
        <div v-show="!interactiveMode && showStdIn">
          <div class="has-text-weight-semibold ide-title">Stdin Inputs</div>
          <div class="control">
            <textarea rows=2 class="textarea" v-model="stdin" name="stdin"></textarea>
          </div>
        </div>
      </div>
      <div class="level execute-section is-hidden-print">
        <div class="level-item">
          <div class="field is-grouped">
            <div class="control">
              <button class="button is-info has-text-dark has-text-weight-bold" @click="tryExecute">
                <span v-show="!isCodeExecuting"><font-awesome-icon icon="play"/>&nbsp;&nbsp;&nbsp;Execute</span>
                <span v-if="isCodeExecuting">
                  <font-awesome-icon icon="spinner" class="fa-spin"/>&nbsp;&nbsp;&nbsp;Executing...</span>
              </button>
            </div>
            <p class="control" v-if="isCodeExecuting">
              <button class="button has-text-weight-bold" @click="stopExecution">
                <span><font-awesome-icon icon="stop-circle"/></span>
              </button>
            </p>
            <div class="control" v-if="!this.$route.meta.isPlugin">
              <div class="dropdown is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button has-text-dark has-text-weight-bold more-button"
                          aria-haspopup="true" aria-controls="dropdown-menu4">
                    <font-awesome-icon icon="ellipsis-h"/>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                  <div class="dropdown-content">
                    <a class="dropdown-item" @click="download">
                      <font-awesome-icon icon="save" class="has-text-grey-dark"/>&nbsp;&nbsp;Save (to local file)
                    </a>
                    <a class="dropdown-item"  @click="prettyPrint">
                      <font-awesome-icon icon="print" class="has-text-grey-dark"/>&nbsp;&nbsp;Pretty Print
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"
                     v-show="doodleForm.errorMessage"/>
      <div class="has-text-weight-semibold ide-title">Result</div>
      <div class="execute-time level is-marginless" v-show="!isCodeExecuting && executionTime">
        <div class="level-left is-marginless is-paddingless" v-show="!interactiveMode"><span>CPU Time: {{this.cpuTime}} sec(s), Memory: {{this.memory}} kilobyte(s)</span>
        </div>
        <div class="is-marginless is-paddingless level-right"><span v-if='isCompileLang'>compiled and&nbsp;</span>executed
          in {{this.executionTime}} sec(s)
        </div>
      </div>
      <div>
        <div id="output" class="code-editor no-border-print"></div>
      </div>
      <div class="embed-brand-area level">
        <div class="level-left hidden-print has-text-left is-underlined info-text">
          <a @click="postToJDoodle" class="has-text-black" v-if="this.$route.meta.isPlugin">Edit this program in JDoodle.com</a>
          <a :href="shareUrl" target="_blank" class="has-text-black" v-else>Edit this program in JDoodle.com</a>
        </div>
        <div class="level-right has-text-right">
          <div class="info-text">
            <span class="powered-by">Online compiler powered by</span>
            <span><a class="jdoodle-brand" href="https://www.jdoodle.com" target="_blank">&nbsp;&nbsp;&nbsp;<img
                src="../assets/images/jdoodle.png" width="75" alt="JDoodle.com"/></a></span>
          </div>
          <div id="ideContainer" class="g-recaptcha is-hidden-print margin-top-20px has-text-centered is-hidden" data-size="invisible" data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi" data-badge="inline"></div>
        </div>
      </div>
    </div>
    <form action="" method="post" style="display: none" id="toJD" target="_blank">
      <textarea id="initScriptForPost" name="initScript" rows="8" cols="80"></textarea>
    </form>
  </div>
</template>

<script>
import aceMixin from './../assets/javascript/ide-mixin'
import { CMD_LANGUAGES, IDE_CONST } from '../assets/javascript/constants'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import { META } from '../assets/javascript/meta'
import { saveAs } from 'file-saver'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'
import { ACE_UTIL } from '@/assets/javascript/ace-util'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'

export default {
  name: 'embedIde',
  components: { DoodleMessage },
  mixins: [aceMixin, formMixin, recaptchaMixin],
  data: function () {
    return {
      interactiveModeOption: false,
      project: null,
      autoSaveOn: false,
      ideMeta: {},
      shareNotFound: false,
      showAccordion: true,
      postUrl: '',
      initScriptForPost: '',
      isFileUploaded: false,
      hasFiles: false,
      files: []
    }
  },
  mounted () {
    if (this.$store.state.isUserLoggedIn === false && this.$store.state.robotChecked === false) {
      this.loadRecaptcha('ideContainer', this.doRoboCheck)
    }

    this.isEmbed = true
    let postAction
    if (this.$route.meta.isPlugin === true) {
      postAction = this.initEmbedPlugin()
    } else {
      postAction = this.initEmbedShare()
    }

    window._.delay(postAction, 600, 1)
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      ACE_UTIL.loadACELibraries()
      loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym.min.js')
    })
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (window.confirm('Do you want to leave ' + this.ideMeta.langDisplayName + ' IDE?')) {
      next()
    } else {
      next(false)
    }
  },
  computed: {
    showStdIn () {
      // eslint-disable-next-line eqeqeq
      return this.$route.query.stdin != 0
    },
    showArgs () {
      // eslint-disable-next-line eqeqeq
      return this.$route.query.arg != 0 && !CMD_LANGUAGES.includes(this.ideMeta.language)
    },
    shareUrl () {
      return 'https://www.jdoodle.com/a/' + this.$route.params.shareId
    },
    interactiveMode () {
      return this.interactiveModeOption === true
    },
    language () {
      return this.ideMeta.language
    },
    versions () {
      return this.ideMeta.versions
    },
    downloadFileName () {
      return 'jdooodle' + this.ideMeta.filename
    },
    aceLanguageCode () {
      return this.ideMeta.aceCode ? this.ideMeta.aceCode : this.ideMeta.language
    },
    isCompileLang () {
      return this.ideMeta.isCompile || this.ideMeta.language === 'java'
    },
    showCommandLine () {
      return !CMD_LANGUAGES.includes(this.ideMeta.language)
    },
    isBlockly () {
      return this.project && this.project.language === 'blockly'
    }
  },
  methods: {
    setVersionIndex: function () {
      if (this.project.versionIndex || window._.isNumber(this.project.versionIndex)) {
        this.versionIndex = parseInt(this.project.versionIndex)
      } else {
        this.versionIndex = this.ideMeta.versions.length - 1
      }
    },
    initEmbedShare () {
      this.args = (this.$route.query.set_args) ? (this.$route.query.set_args) : null
      this.executeAPIWitoutValiation({
        url: '/api/doodle/shareIndex',
        data: { shareId: this.$route.params.shareId, isInstant: this.$route.meta.isInstantShare },
        method: 'post',
        successAction: (data) => {
          this.ideMeta = META[data.project.language]
          window._.delay(this.initEditors, 500, 1)
          let postAction = (project, count) => {
            if (window.ace) {
              this.pymChild = new window.pym.Child()
              this.project = project
              this.setVersionIndex()
              this.javaLibraries = this.$_.compact(project.libraries.split(' '))
              this.loadBlockly(this.ideMeta.language, this)
            } else if (count < 10) {
              window._.delay(postAction, 600, project, count + 1)
            }
          }
          window._.delay(postAction, 600, data.project, 1)
        },
        failureAction: () => {
          this.shareNotFound = true
        },
        markCompleted: true,
        form: this.doodleForm
      })

      let postAction = (project, count) => {
        if (window.pym) {
          window.pym.Child({ polling: 500 })
        } else if (count < 10) {
          window._.delay(postAction, 600, count + 1)
        }
      }

      return postAction
    },
    initEmbedPlugin () {
      this.ideMeta = META['java']
      window._.delay(this.initEditors, 500, 1)
      let postAceAction = (count) => {
        if (window.ace) {
          this.pymChild = new window.pym.Child()

          this.pymChild.onMessage('getCode', () => {
            window.parent.postMessage({ script: this.codeEditor.getSession().getValue() }, '*')
          })

          this.pymChild.onMessage('setScript', (data) => {
            data = JSON.parse(data)
            if (data.id === this.$route.query.id) {
              this.theme = data.theme
              this.initScripts(data)
            }
          })

          this.javaLibraries = this.javaLibs
          this.pymChild.sendMessage('getScript', JSON.stringify({ id: this.$route.query.id }))
        } else if (count < 20) {
          window._.delay(postAceAction, 600, count + 1)
        }
      }

      let postAction = (count) => {
        if (window.pym) {
          window.pym.Child({ polling: 500 })
          window._.delay(postAceAction, 100, 1)
        } else if (count < 20) {
          window._.delay(postAction, 600, count + 1)
        }
      }

      return postAction
    },
    ideExecute () {
      if (this.hasFiles && !this.isFileUploaded) {
        this.executeAPIWitoutValiation({
          url: '/api/projectSync/uploadInputFileWithText',
          method: 'post',
          data: { projectKey: 1001, content: this.files[0].content, name: this.files[0].name },
          successAction: (data) => {
            this.inputFiles = data.inputFiles
            this.isFileUploaded = true
            this.execute(false)
          },
          failureAction: (status) => {
            if (status === 403) {
              this.$store.commit('clearRobotCheck')
            }
            this.showErrorMessage('Unable to Sync the file with server!')
          },
          markCompleted: false,
          form: this.doodleForm,
          jdaCategory: window.jda.CATEGORY.IDE,
          jdaEvent: 'project-tree-update-file',
          jdaLabel: this.language
        })
      } else {
        this.execute(false)
      }
    },
    postExecuteSuccessHandling () {

    },
    download () {
      let code = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
      let fileName = this.downloadFileName
      if (this.language === 'java') {
        // eslint-disable-next-line no-useless-escape
        let regex = /(public)([\s]*)(class)([\s]*)([\w\$]*)([\s]*)({)/
        let match = regex.exec(code)
        if (match && match.length >= 5) {
          fileName = match[5] + '.java'
        } else {
          fileName = 'jdoodle.java'
        }
      }

      let file = new File([code], fileName, { type: 'text/plain;charset=utf-8' })
      saveAs(file)
    },
    prettyPrint () {
      window.print()
    },
    revertAccordion () {
      this.showAccordion = !this.showAccordion
    },
    initScripts (data) {
      this.project = data

      this.hasFiles = data.hasFiles
      this.files = data.files

      if (this.project.libs) {
        this.project.libs = this.project.libs.split(',')
      }

      if (this.project.language !== 'blockly') {
        this.onLanguageChange()
      }

      this.setVersionIndex()

      if (!this.project.script || this.project.script < 1) {
        this.runDisabled = true
      }
      this.loadBlockly(this.project.language, this)
    },
    onLanguageChange () {
      this.outputFiles = []
      this.showOutput = false
      this.ideMeta = META[this.project.language]
      this.setVersionIndex()

      if (!this.isBlockly) {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(this.project.script)
        ACE_UTIL.changeLanguage(this.codeEditor, this.aceLanguageCode, this.theme)
      } else {
        this.versionChanged()
      }
    },
    postToJDoodle () {
      if (process.env.NODE_ENV !== 'development') {
        this.postUrl = 'https://www.jdoodle.com/api/redirect-to-post/' + this.ideMeta.canonicalPath
      } else {
        this.postUrl = 'http://localhost:8080/api/redirect-to-post/' + this.ideMeta.canonicalPath
      }

      this.initScriptForPost = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
      $('#initScriptForPost').val(this.initScriptForPost)
      $('#toJD').attr('action', this.postUrl)
      $('#toJD').submit()
    }
  },
  watch: {
    project (val) {
      if (val) {
        if (this.isBlockly) {
          if (window.Blockly && val.script) {
            window.Blockly.serialization.workspaces.load(JSON.parse(val.script), this.blocklyWorkspace, false)
          }
        } else {
          window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(val.script)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";
  @import "./../assets/style/ide";
  @import "../../node_modules/bulma-accordion/dist/css/bulma-accordion.min.css";

  .home-container {
    margin: 1em;
  }

  .main-box {
    padding: 0.5em 1.2em;
  }

  .ide-title, .switch[type=checkbox] + label.ide-title {
    font-size: 0.9em;
  }

  .column {
    padding-top: 0.15em;
    padding-bottom: 0.10em;
  }

  .interactive-mode-top {
    margin-top: 0.75em;
  }

  .embed-brand-area {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
  }

  .info-text {
    font-size: 0.85em;
    color: black;
    font-weight: 500;
  }

  .powered-by {
    vertical-align: bottom;
  }

  .accordion-header {
    font-size: 0.85em;
  }

  .background-grey-light {
    background: #b5b5b5 !important;
  }

  .code-editor-box {
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-bottom: 0.1rem;
    padding: 0.25rem;
    .accordions {
      margin-top: 0.25em;
    }
  }

  .execute-section {
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  .padding-left-none {
    padding-left: 0 !important;
  }

  .switch[type=checkbox]:checked+label::before, .switch[type=checkbox]:checked+label:before {
    background: #607d8b;
  }

  .blockly-editor {
    margin-bottom: 0.1rem;
    min-height: 400px;
  }

  .blockly-editor #code {
    min-height: 400px;
  }

</style>
